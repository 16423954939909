






























































































































































































































































































































































































































































































































































.el-transfer {
  /*Hack element*/
  .el-transfer-panel {
    min-width: calc(50% - 15px);

    .el-transfer-panel__header .el-checkbox .el-checkbox__label span {
      &:before {
        content: ' ';
        background: #F5F7FA;
        display: block;
        width: 11px;
        height: 17px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &__item.el-checkbox .el-checkbox__label {
      padding-left: 0;
    }
  }

  .el-transfer__buttons {
    visibility: hidden;
    width: 30px;
    padding: 0;
  }

  .el-checkbox__input {
    display: none;
  }

  .el-checkbox__label {
    padding-left: 0;
  }

}

.mb-15 {
  margin-bottom: 15px;
}

.substances label {
  display: block;
}

.productSheet-select {
  max-width: 300px;
}

.substances .portlet {
  margin-bottom: 20px;
}

.add-substance {
  margin-top: 20px;
}

.z-1 {
  height: 115px;

  .productSheet-options {
    padding: 5px 0;

    span:first-child {
      line-height: 16px;
      font-size: 14px;
      padding-bottom: 3px;
    }

    div:last-child {
      margin-top: 5px;
    }
  }

  .options-num {
    font-size: 12px;
    line-height: 16px;
  }
}

.el-select-dropdown__item.is-disabled.removed-disabled {
  cursor: pointer;
  color: inherit;
}
